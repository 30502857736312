export default [
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
  },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/todo',
    name: 'apps-todo',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/apps/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },

  // Invoice
  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
  },
  {
    path: '/apps/invoice/add/',
    name: 'apps-invoice-add',
    component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
  },
  {
    path: '/apps/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
  },

  // Excerpt
  {
    path: '/apps/excerpt/list',
    name: 'apps-excerpt-list',
    component: () => import('@/views/apps/excerpt/excerpt-list/ExcerptList.vue'),
  },
  {
    path: '/apps/excerpt/preview/:id',
    name: 'apps-excerpt-preview',
    component: () => import('@/views/apps/excerpt/excerpt-preview/ExcerptPreview.vue'),
  },
  {
    path: '/apps/excerpt/add/',
    name: 'apps-excerpt-add',
    component: () => import('@/views/apps/excerpt/excerpt-add/ExcerptAdd.vue'),
  },
  {
    path: '/apps/excerpt/converter/',
    name: 'apps-excerpt-converter',
    component: () => import('@/views/apps/excerpt/excerpt-converter/ExcerptConverter.vue'),
  },
  {
    path: '/apps/excerpt/search/:folder/:label/',
    name: 'apps-excerpt-search-folder',
    component: () => import('@/views/apps/excerpt/excerpt-search/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-excerpt-search-folder',
    },
    beforeEnter(to, _, next) {
      if (['Info', 'Users', 'Map', 'FilePlus', 'all', '', undefined].includes(to.params.folder) && ['7days', '30days', '90days', '1year', 'all', '', undefined].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/order/list/:folder/:label/',
    name: 'apps-order-list-folder',
    component: () => import('@/views/apps/e-commerce/e-commerce-orders/Orders.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-order-list-folder',
    },
    beforeEnter(to, _, next) {
      if (['Info', 'Users', 'Map', 'FilePlus', 'all', '', undefined].includes(to.params.folder) && ['7days', '30days', '90days', '1year', 'all', '', undefined].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/e-commerce/checkout',
    name: 'apps-e-commerce-checkout',
    component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
    meta: {
      pageTitle: 'Checkout',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Checkout',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/point/list',
    name: 'apps-point-list',
    component: () => import('@/views/apps/e-commerce/e-commerce-point-list/PointList.vue'),
  },

  {
    path: '/apps/excerpt/edit/:id',
    name: 'apps-excerpt-edit',
    component: () => import('@/views/apps/excerpt/excerpt-edit/ExcerptEdit.vue'),
  },
  {
    path: '/pages/faq',
    name: 'pages-faq',
    component: () => import('@/views/pages/faq/Faq.vue'),
    meta: {
      pageTitle: 'Помощь',
      breadcrumb: [
        {
          text: 'Частые вопросы',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/partners',
    name: 'pages-partners',
    component: () => import('@/views/pages/partners/Partners.vue'),
    meta: {
      pageTitle: 'Партнёрская программа',
    },
  },
  {
    path: '/apps/e-commerce/checkout',
    name: 'apps-e-commerce-checkout',
    component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
    meta: {
      pageTitle: 'Checkout',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/points/add',
    name: 'app-points-add',
    component: () => import('@/views/apps/points/points-add/PointsAdd.vue'),
    meta: {
      pageTitle: 'Пополнить баланс',
    },
  },
]
