import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  faqData: {
    // payment
    payment: {
      icon: 'PlayCircleIcon',
      title: 'С чего начать?',
      subtitle: 'Здесь находятся инструкции для начала работы с архивом',
      qandA: [
        {
          question: 'Где можно найти файлы загруженные другими участниками архива?',
          ans:
            'Файлы Вы можете найти в разделе "Архив"',
        },
        {
          question: 'Где можно найти файлы загруженные мной?',
          ans:
            'Файлы Вы можете найти в разделе "Ваши файлы"',
        },
        {
          question: 'Где можно найти файлы, которые, я купил?',
          ans:
            'Файлы Вы можете найти в разделе "Заказы"',
        },
        {
          question: 'Как загрузить файлы в архив?',
          ans:
            'Перейдите в раздел "Загрузить" и просто перетащите файлы в область загрузки файлов. После успешной обработки файлы будут доступны Всем пользователям архива за баллы',
        },
        {
          question: 'Сколько по времени происходит обработка загруженных мною файлов?',
          ans:
            'Как правило в течении 10-20 секунд после загрузки, но бывают задержки до 24 часов',
        },
      ],
    },
    // delivery
    delivery: {
      icon: 'WindIcon',
      title: 'Баллы',
      subtitle: 'Здесь Вы найдёте всё про баллы',
      qandA: [
        {
          question: 'Как я могу получить баллы?',
          ans:
            'Вы получите баллы если: Вы загрузите валидный файл (10 баллов). Ваш реферал загрузит валидный файл (5 баллов). Вы продадите файл (50 баллов). Ваш реферал продаст файл (25 баллов). Вы приобретёте баллы за рубли (3000 баллов = 100 рублей).',
        },
        {
          question: 'Что такое баллы?',
          ans:
            'Баллы - это внетреняя валюта архива, которую можно получить за определённые действия с файлами в системе и приобрести за рубли',
        },
        {
          question: 'Где я могу посмотреть, количество моих баллов',
          ans:
            'Вверху страницы с права от логитипа МойЕГРН. Внимание! Это значение сохранено у Вас на устройстве, реальное кол-во баллов охранено на серверах архива. Для получения актуального кол-ва баллов нужно нажать на кнопку "с двумя стрелочками" слева от баланса.',
        },
        {
          question: 'Где я могу посмотреть, историю начисления и списания баллов',
          ans:
            'В разделе "Баллы"',
        },
        {
          question: 'Возможно ли обменять баллы на рубли?',
          ans:
            'Обмен баллов на рубли в настоящий момент не доступен. Функционал, курс и условия выплат в разработке',
        },
      ],
    },
    // product and services
    productServices: {
      icon: 'FileTextIcon',
      title: 'Правила архива',
      subtitle: 'Здесь расположен свод правил, которых, обязан придерживаться каждый его пользователь',
      qandA: [
        {
          question: 'Правило №1',
          ans:
            'Архив построен на условиях добровольности участия и учёте вкладов участников, неотказуемости участникам в выдаче и возмещении их вклада.',
        },
        {
          question: 'Правило №2',
          ans:
            'Участник архива может скачать столько файлов, на сколько ему хватит баллов.',
        },
        {
          question: 'Правило №3',
          ans:
            'Загружая файл, участник архива соглашается с тем, что этот файл может быть скачан любым другим участником архива безвозмездно. Файл может безвозмездно и без ограничения срока храниться на сервере архива, удаление файла не производится.',
        },
        {
          question: 'Правило №4',
          ans:
            'Участник может скачивать свой ранее загруженный файл без ограничений по количеству раз.',
        },
        {
          question: 'Правило №5',
          ans:
            'Если участник ранее скачивал файл, то и в последующем он может скачивать этот файл без ограничений.',
        },
        {
          question: 'Правило №6',
          ans:
            'Поскольку файлы в архив загружают участники, то создатели архива не несут никакой ответственности за достоверность и полноту сведений, не имеют лицензионных обязательств.',
        },
        {
          question: 'Правило №7',
          ans:
            'В архиве нет ограничений на количество скачиваемых файлов в день, ограничением является лишь Ваш баланс баллов',
        },
        {
          question: 'Правило №8',
          ans:
            'В целях защиты архива от злоумышленников: обеспечения достоверности файлов архива и предупреждения краж файлов из архива, обработка файлов может длиться до 24 часов. Это время необходимо для ручной проверки загруженных файлов и выявления фальсификаций. Учетные записи злоумышленников блокируются. Мы за справедливость и чистый архив.',
        },
        {
          question: 'Правило №9',
          ans:
            'В целях поддержания в архиве актуальных сведений, файлы принимаются только с ЭЦП и проверяются на целостность',
        },
        {
          question: 'Правило №10',
          ans:
            'Начисление и расходование баллов по всем типам файлов осуществляется совместно, то есть ведётся один счет.',
        },
        {
          question: 'Правило №11',
          ans:
            'Баллы можно приобрести за плату, они  начинают действовать сразу после оплаты и обработки платежа.',
        },
        {
          question: 'Правило №12',
          ans:
            'Сведения, полученные участником Архива в данном сервисе, не могут быть предоставлены заинтересованным лицам - согласно п. 25 ст. 1 Федерального закона № 120-ФЗ.',
        },
        {
          question: 'Правило №13',
          ans:
            'Архив соответствует требованиям Федерального Закона №120-ФЗ, поскольку сервис не предоставляет доступ к информационным ресурсам Росреестра (п. 25 ст. 1), а участники Архива передают файлы в сервис на безвозмездной основе (п. 24 ст. 1).',
        },
      ],
    },
  },
}

mock.onGet('/faq/data').reply(config => {
  const { q = '' } = config.params
  const queryLowered = q.toLowerCase()

  const filteredData = {}

  Object.entries(data.faqData).forEach(entry => {
    const [categoryName, categoryObj] = entry
    // eslint-disable-next-line arrow-body-style
    const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
      return qAndAObj.question.toLowerCase().includes(queryLowered)
    })
    if (filteredQAndAOfCategory.length) {
      filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
    }
  })

  return [200, filteredData]
})
