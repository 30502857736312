import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
// import ecommerce from './routes/e-commerce'
// import dashboard from './routes/dashboard'
// import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
// import chartsMaps from './routes/charts-maps'
// import formsTable from './routes/forms-tables'
// import others from './routes/others'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // { path: '/', redirect: { name: 'apps-excerpt-search-folder', params: { label: 'all', folder: 'all' } } },
    ...apps,
    // ...ecommerce,
    // ...dashboard,
    ...pages,
    // ...chartsMaps,
    // ...formsTable,
    // ...uiElements,
    // ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  // console.log('ability islogin')
  if (!canNavigate(to)) {
    // console.log('ability canNavigate ', to)
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      // console.log('ability isLoggedIn ', !isLoggedIn)
      return next({ name: 'auth-login' })
    }

    // console.log('ability isLogmisc-not-authorizedgedIn ')
    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // eslint-disable-next-line radix
  if (localStorage.getItem('userDataTicks') && parseInt(localStorage.getItem('userDataTicks')) < new Date().getTime()) {
    // console.log('ability reload')
    window.location.reload()
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    // console.log('ability redirectIfLoggedIn')
    const userData = getUserData()
    // eslint-disable-next-line no-nested-ternary
    next(getHomeRouteForLoggedInUser(userData ? (userData.role ? userData.role : '') : null))
  }
  // console.log('ability next')
  return next()
})

export default router
