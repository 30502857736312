import { Ability } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

// console.log('ability init')

// eslint-disable-next-line radix
if (!localStorage.getItem('userDataTicks') || parseInt(localStorage.getItem('userDataTicks')) < new Date().getTime()) {
  // console.log('ability clear')
  localStorage.removeItem('userData')
  localStorage.removeItem('accessToken')
  localStorage.removeItem('userData')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('userDataTicks')
}

const userData = JSON.parse(localStorage.getItem('userData'))
const existingAbility = userData ? userData.ability : null
export default new Ability(existingAbility || initialAbility)
