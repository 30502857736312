import axios from '@axios'
import { endpoint } from '@/@core/endpoint'

export default {
  namespaced: true,
  state: {
    cartItems: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [],
    balance: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).balance : [],
    selectFiles: localStorage.getItem('selectFiles') ? JSON.parse(localStorage.getItem('selectFiles')) : [],
    lostOrderInfo: {},
    isDownload: false,
  },
  getters: {
    cartItemCnt: state => state.cartItems.length,
    cartItems: state => state.cartItems,
    isCard: state => idFile => state.cartItems.filter(ii => ii.id === idFile).length !== 0,

    selectFilesCnt: state => state.selectFiles.length,
    selectFiles: state => state.selectFiles,
    isSelectFiles: state => idFile => state.selectFiles && state.selectFiles.includes(idFile),
    isSelectFilesIdds: state => idds => idds.length === state.selectFiles.filter(ii => idds.includes(ii)).length,
    isSelectFilesIddsSingle: state => idds => state.selectFiles.filter(ii => idds.includes(ii)).length > 0,
    selectFilesFolder: state => idds => state.selectFiles.filter(ii => idds.includes(ii)),
    balance: state => state.balance,
    lostOrderInfo: state => state.lostOrderInfo,
    isDownload: state => state.isDownload,
  },
  mutations: {
    SET_IS_DOWNLOAD(state, isDownload) {
      state.isDownload = isDownload
    },
    CLEAR_SELECT_FILES(state) {
      state.selectFiles = []
      localStorage.setItem('selectFiles', JSON.stringify(state.selectFiles))
    },
    REMOVE_SELECT_FILE(state, idFile) {
      state.selectFiles = state.selectFiles.filter(ii => ii !== idFile)
      localStorage.setItem('selectFiles', JSON.stringify(state.selectFiles))
    },
    ADD_SELECT_FILE(state, idFile) {
      if (!state.selectFiles.includes(idFile)) {
        state.selectFiles.push(idFile)
        localStorage.setItem('selectFiles', JSON.stringify(state.selectFiles))
      }
    },

    CLEAR_CART(state) {
      state.cartItems = []
      localStorage.setItem('cart', JSON.stringify(state.cartItems))
    },
    REMOVE_PRODUCT_FROM_CART(state, idFile) {
      state.cartItems = state.cartItems.filter(ii => ii.id !== idFile)
      localStorage.setItem('cart', JSON.stringify(state.cartItems))
    },
    ADD_PRODUCT_TO_CART(state, file) {
      if (!file.isYou && !state.cartItems.filter(ii => ii.id === file.id).length) {
        state.cartItems.push(file)
        localStorage.setItem('cart', JSON.stringify(state.cartItems))
      }
    },
    SET_BALANCE(state, balance) {
      state.balance = balance
      if (localStorage.getItem('userData')) {
        const userData = JSON.parse(localStorage.getItem('userData'))
        userData.balance = balance
        localStorage.setItem('userData', JSON.stringify(userData))
      } else {
        localStorage.setItem('userData', JSON.stringify({ balance }))
      }
    },
    SET_LOST_ORDER_INFO(state, info) {
      state.lostOrderInfo = info
    },
  },
  actions: {
    fetchUserBalance(ctx, payload) {
      return new Promise((resolve, reject) => {
        // console.log(ctx)
        axios
          .get(`${endpoint()}/user/balance`, { params: payload })
          .then(response => {
            ctx.commit('SET_BALANCE', response.data.Data.balance)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    createNewOrder(ctx, idds) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${endpoint()}/order/set`, { idds })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProducts(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/ecommerce/products', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/ecommerce/products/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWishlistProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/ecommerce/wishlist')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCartProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/ecommerce/cart')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // ------------------------------------------------
    // Product Actions
    // ------------------------------------------------
    addProductInWishlist(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/ecommerce/wishlist', { productId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromWishlist(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/ecommerce/wishlist/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addProductInCart(ctx, { file }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/ecommerce/cart', { file })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addNewOrder(ctx, { idds }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/ecommerce/cart', { idds })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromCart(ctx, { idFile }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/ecommerce/cart/${idFile}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
